$color-primary: #BF9456;
$color-secondary: #354755;

.Title {
  margin-bottom: 2.5em;
  text-align: center;

  @media (min-width: 992px) {
    text-align: left;
  }

  &.Center {
    text-align: center;
  }
  
  .Quote {
    Color: $color-secondary;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .Text {
    color: $color-primary;
    font-family: 'Playfair Display', serif;
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 0;
  }
}
