$color-primary: #BF9456;
$color-secondary: #354755;

.Navbar {
  background-color: white;
  padding-top: 0.938em;
  padding-bottom: 0.938em;

  .Logo {
    padding-top: 0;
    padding-bottom: 0;

    .Image {
      display: block;
      height: 3.5em;
    }
  }

  .Toggle {
    border-color: $color-secondary;
    outline: 0;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($color-secondary, 0.5) !important;
    }

    .Icon {
      fill: $color-secondary;
    }
  }

  .Link {
    cursor: pointer;
    color: $color-secondary !important;
    font-family: 'Montserrat', sans-serif;
    text-align: center;

    @media (min-width: 992px) {
      text-align: left;
    }

    &.isActive {
      color: $color-primary !important;
    }
  }

  .Buttons {
    align-items: flex-start;

    @media (min-width: 992px) {
      align-items: center;
    }

    .Register,
    .Login {
      margin: 8px auto;

      @media (min-width: 992px) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .Register {

      @media (min-width: 992px) {
        margin-right: 8px;
      }
    }

    .Login {
      
      @media (min-width: 992px) {
        margin-left: 8px;
      }
    }
  }
}
