$color-primary: #BF9456;
$color-secondary: #354755;

.Slider {

  :global(.carousel-indicators) {
    margin-bottom: 1.250em;
  
    li {
      background-color: white;
      border-radius: 5px;
      border-top: 0;
      border-bottom: 0;
      height: 0.313em;
      margin-right: 0.313em;
      margin-left: 0.313em;
      opacity: 1;
      width: 2.5em;
  
      &:global(.active) {
        background-color: $color-primary;
      }
    }
  }

  :global(.carousel-inner) {
    height: 100%;
  }
}

.Item {
  height: 100%;

  &::before {
    background-image: linear-gradient(90deg,rgba(0, 0, 0, 0.8) 0%,rgba(0, 0, 0, 0.2) 100%);
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
}

.Image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.Caption {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;

  @media (min-width: 992px) {
    text-align: left;
  }
}

.Title {
  font-family: 'Playfair Display', serif;
  font-size: 3.25em;
  font-weight: 700;
  margin-bottom: 0.462em;
}

.Paragraph {
  font-family: 'Montserrat', sans-serif;

  &:last-of-type {
    margin-bottom: 2em;
  }
}
