@import "../../../sass/variables.scss";
@import "../../../sass/functions.scss";

.Button {
  border-radius: 50rem;
  font-family: 'Montserrat', sans-serif;
  font-size: em(14px);
  font-weight: 600;
  padding: 0.929em 2.143em;
  min-width: 10em;

  &.Primary,
  &.Secondary {
    color: white;
  }

  &.Primary {
    background-color: $color-primary;

    &:hover {
      background-color: darken($color-primary, 5%);
      border-color: darken($color-primary, 5%);
    }

    &:active,
    &:focus {
      background-color: darken($color-primary, 10%) !important;
      border-color: darken($color-primary, 10%) !important;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.5) !important;
    }

    svg {
      fill: #FFFFFF;
      vertical-align: sub;
      margin-right: em(5px, 14px);
    }
  }

  &.Secondary {
    background-color: $color-secondary;

    &:hover {
      background-color: darken($color-secondary, 5%);
      border-color: darken($color-secondary, 5%);
    }

    &:active,
    &:focus {
      background-color: darken($color-secondary, 10%) !important;
      border-color: darken($color-secondary, 10%) !important;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($color-secondary, 0.5) !important;
    }

    svg {
      fill: #FFFFFF;
      vertical-align: sub;
      margin-right: em(5px, 14px);
    }
  }

  &.Light {
    color: $color-primary;
    background-color: white;

    &:hover {
      background-color: darken(white, 5%);
      border-color: darken(white, 5%);
    }

    &:active,
    &:focus {
      background-color: darken(white, 10%) !important;
      border-color: darken(white, 10%) !important;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(darken(white, 5%), 0.5) !important;
    }
  }

  &.Danger {
    color: white;
    background-color: $color-danger;

    &:hover {
      background-color: darken($color-danger, 5%);
      border-color: darken($color-danger, 5%);
    }

    &:active,
    &:focus {
      background-color: darken($color-danger, 10%) !important;
      border-color: darken($color-danger, 10%) !important;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($color-danger, 0.5) !important;
    }

    svg {
      fill: #FFFFFF;
      vertical-align: sub;
      margin-right: em(5px, 14px);
    }
  }

  &.Left,
  &.Center,
  &.Right {
    display: block;
  }

  &.Left {
    margin-right: auto;
  }

  &.Center {
    margin-right: auto;
    margin-left: auto;
  }

  &.Right {
    margin-left: auto;
  }

  &.MarginRight {
    margin-right: 1.071em;
  }

  &.Small {
    font-size: 0.75em;

    &.MarginRight {
      margin-right: 1.25em;
    }
  }

  &.Ghost {
    background-color: transparent;

    &.Primary {
      color: $color-primary;
      border-color: $color-primary;

      &:hover,
      &:active,
      &:focus {
        color: white;
      }
    }
  }
}
