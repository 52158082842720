$color-primary: #BF9456;
$color-secondary: #354755;

.Timeline {

  .Block {
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    
    &:not(:last-child) {
      margin-bottom: 2.5em;

      .Point::after {
        background-color: $color-primary;
        content: '';
        display: block;
        height: calc(100% - 30px);
        margin-right: auto;
        margin-left: auto;
        width: 4px;
      }
    }
  }

  .Point {

    &.isActive {

      .Circle {
        background-color: $color-primary;
      }

      .Icon {
        fill: #FFFFFF;
      }
    }

    .Circle {
      border: 4px solid $color-primary;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4.375em;
      width: 4.375em;
    }

    .Icon {
      fill: $color-primary;
    }
  }

  .Content {
    margin-left: 1.25em;
  }

  .Title {
    color: $color-secondary;
    font-family: 'Playfair Display', serif;
    font-size: 1.25em;
    font-weight: 700;
    margin-bottom: 0.5em;
  }

  .Paragraph {
    color: $color-secondary;
    font-family: 'Montserrat', sans-serif;
    font-size: 1em;
    margin-bottom: 0;
  }
}
