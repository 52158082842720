$color-primary: #BF9456;
$color-secondary: #354755;

.Overlap {
  display: grid;
  grid-template-rows: 72px 1fr 72px;
  grid-template-columns: 72px 1fr 72px;
  max-width: 27.438em;

  &:hover {

    .Translate {
      transform: translateX(-5%);
    }
  }

  .Translate {
    transition: transform 300ms ease-in-out;
  }

  .Front,
  .Back {
    position: relative;
  }

  .Front {
    grid-row: 1 / 3;
    grid-column: 2 / 4;
    z-index: 1;
  }

  .Back {
    grid-row: 2 / 4;
    grid-column: 1 / 3;
  }

  .Spinner {
    border-top-color: $color-primary;
    border-bottom-color: $color-primary;
    border-left-color: $color-primary;
    margin-top: -14px;
    margin-left: -14px;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .Image {
    display: block;
    opacity: 0;
    transition: all 0.25s ease-in-out;
    visibility: hidden;
    width: 100%;

    &.Visible {
      visibility: visible;
      opacity: 1;
    }
  }
}
