@function em($pixel, $context: $font-size-default) {
  $ems: 1em;

  @if not is-pixel($pixel) {
    @error "El parametro $pixel no contiene un valor en pixeles.";
  }

  @if not is-pixel($context) {
    @error "El parametro $context no contiene un valor en pixeles.";
  }

  @return ($pixel / $context) * $ems;
}

@function is-pixel($value) {
  @if type-of($value) == "number" {
    @if unit($value) == "px" {
      @return true;
    }
  }

  @return false;
}
