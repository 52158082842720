$color-secondary: #354755;

.Ventajas {

  .Graphic {

    @media (min-width: 992px) {
      padding-top: 40px;
      padding-bottom: 40px;
      position: relative;
    }
  }

  .Image {
    display: block;
    margin-right: auto;
    margin-bottom: 2.5em;
    margin-left: auto;
    max-width: 100%;

    @media (min-width: 992px) {
      margin-bottom: 0;
    }
  }

  .List {

    @media (min-width: 992px) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .Item {

      @media (min-width: 992px) {
        margin-right: 0;
        margin-left: 0;
        position: absolute;
        right: 0;
        left: 0;

        &:nth-child(1) {
          margin-top: -4.063em;
          top: 50%;
        }

        &:nth-child(2) {
          top: 0;
        }

        &:nth-child(3) {
          margin-top: -6.313em;
          top: 50%;
        }

        &:nth-child(4) {
          bottom: 0;
        }
      }

      @media (min-width: 1199px) {

        &:nth-child(3) {
          margin-top: -5.563em;
        }
      }
    }
  }

  .Ventaja {
    margin-bottom: 2.5em;

    @media (min-width: 992px) {
      margin-bottom: 0;

      &.Right {
        text-align: right;
        padding-right: 5em;
      }

      &.Left {
        padding-left: 5em;
      }
    }

    &.Last {
      margin-bottom: 0;
    }

    .Title,
    .text {
      color: $color-secondary;
    }

    .Title {
      font-family: 'Playfair Display', serif;
      font-weight: 700;
      margin-bottom: 0.5em;
    }

    .Text {
      font-family: 'Montserrat', sans-serif;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
