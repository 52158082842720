$color-primary: #BF9456;
$color-secondary: #354755;

.Plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E0CDAF;
  margin-top: 2.5em;
  margin-bottom: 2.5em;
  padding-top: 2.5em;
  padding-bottom: 2.5em;
  text-align: center;
  width: 100%;
  transition: box-shadow 0.3s ease-in-out,
              transform 0.3s ease-in-out;

  @media (max-width: 767px) {
    margin: 0 2.5em;
    width: auto;
  }

  &.Scale {

    &:hover {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      transform: scale(1.1);
      z-index: 2;
    }
  }

  &.isActive {
    background-color: $color-primary;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    margin-top: 0;
    margin-bottom: 0; 
    padding-top: 5em;
    padding-bottom: 5em;
    position: relative;

    @media (max-width: 767px) {
      margin-right: 0;
      margin-left: 0;
    }

    &.Scale {

      &:hover {
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
      }
    }

    .Type,
    .Price,
    .Paragraph {
      color: white;
    }

    .Type {
      font-size: 1.125em;
      margin-bottom: 1.111em;
    }

    .Price {
      font-size: 3em;
      margin-bottom: 0.833em;

      .Recurrence {
        font-size: 0.333em;
      }
    }

    .Paragraph {
      font-weight: 600;
    }
  }

  .Type,
  .Price,
  .Paragraph {
    color: $color-secondary;
    font-family: 'Montserrat', sans-serif;
  }

  .Type {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 1.25em;
    text-transform: uppercase;
  }

  .Price {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 1.25em;
    text-transform: uppercase;

    .Recurrence {
      display: block;
      font-size: 0.438em;
      font-weight: 400;
    }
  }

  .Description {
    margin-bottom: 1.25em;
    height: 100%;
  }

  .Paragraph {
    margin-bottom: 0;
  }
}
