$color-secondary: #354755;

:global(#thm_mp_cntnr) {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.PlansPage {
  position: relative;
  min-height: 100vh;
  padding-bottom: 9.500em;

  @media (min-width: 992px) {
    padding-bottom: 8.125em;
  }

  .Logo {
    padding-top: 0;
    padding-bottom: 0;

    .Image {
      display: block;
      height: 3.5em;
    }
  }
}

.Navbar {
  background-color: white;
  margin-bottom: 2.5em;
}

.Title {
  color: $color-secondary;
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 0.25em;
  margin-bottom: 1.25em;
  text-align: center;
  text-transform: uppercase;
}

.StepProgressBar {
  margin-bottom: 2.5em;
}

.Plans {
  padding-bottom: 5em;
}

.Line {
  margin-top: 2.5em;
  margin-bottom: 2.5em;
}

.BackButton {
  display: block;
  line-height: 1em;
  padding: 0;
  min-width: auto;
}

.Copyright {
  position: absolute;
  bottom: 0;
}
