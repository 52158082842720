.Overlap {
  margin-bottom: 5em;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 992px) {
    margin-bottom: 0;
  }
}
